.fc-daygrid-day-number {
	width: 100%;
	padding: 0 !important;
}

.fc-daygrid-day-frame {
	position: relative;
	height: 120px !important;
	@media (max-width: theme('screens.md')) {
		height: 80px !important;
	}
}

.fc-daygrid-day-top {
	opacity: 1 !important;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.fc-daygrid-day-events {
	position: absolute !important;
	left: 0;
	bottom: 0;
	width: 100%;
}

.fc-theme-standard th {
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	text-align: left !important;
}

.fc-scrollgrid {
	border-top: transparent !important;
	// border-left: transparent !important;
}

.fc-day:first-child {
	border-left: 1px solid #eee !important;
}

.fc-scrollgrid::after {
	content: '';
	width: 10px;
	height: 25px;
	background-color: white;
	top: 0;
	left: 0;
	position: absolute;
}

.custom-fc-day-blocked {
	background-color: theme('colors.gray.50');
	position: relative;
	&_ical{
		background-color: #FFE9E8 !important;

	}
}

.fc-col-header .fc-scrollgrid-sync-inner a {
	display: flex;
	justify-content: center;
	padding-top: theme('spacing.2');
	padding-bottom: theme('spacing.2');
	font-size: 14px;
	@media (min-width: theme('screens.md')) {
		font-size: 16px;
		padding-top: theme('spacing.4');
		padding-bottom: theme('spacing.4');
	}
}

.fc-daygrid-event {
	overflow: hidden;
	border: none !important;
	box-shadow: none !important;
	border-radius: theme('borderRadius.DEFAULT');
	transform: translate(0, -2px);
	@media (min-width: theme('screens.md')) {
		transform: translate(0, -10px);
		border-radius: 999px;
	}
}

.fc-daygrid-event.fc-event-start {
	margin-left: 16px !important;
}
.fc-daygrid-event.fc-event-end {
	margin-right: -16px !important;
}

.fc-day-past {
	background-color: theme('colors.gray.50');
}

.svg-stroke {
	position: absolute;
	width: 80%;
	height: 80%;
}
.fc-day-today {
	background-color: theme('colors.gray.50') !important;
}
