.featureItem {
	$block: &;
	display: flex;
	align-items: center;

	&__icon {
		display: flex;
		svg {
			width: var(--icon-size);
			height: var(--icon-size);
		}
	}

	&__title {
		font-family: theme('fontFamily.title');
	}

	&__description {
		color: theme('colors.gray.300');
	}

	&--orientation-horizontal {
		#{$block} {
			&__icon {
				margin-right: var(--gap-horizontal);
			}
			&__title {
				text-transform: uppercase;
				font-weight: bold;
				font-size: theme('fontSize.xs');
				color: theme('colors.primary.500');
			}
		}
	}

	&--orientation-vertical {
		text-align: center;
		flex-direction: column;
		#{$block} {
			&__icon {
				margin-bottom: var(--gap-vertical);
				color: theme('colors.primary.500');
			}
			&__title {
				font-weight: 500;
				font-size: theme('fontSize.xl');
				line-height: 1.6;
			}
			&__text {
				font-size: theme('fontSize.sm');
			}
		}
	}

	&--size-sm {
		--gap-horizontal: theme('spacing.3');
		--icon-size: theme('spacing.6');
	}

	&--size-lg {
		--gap-horizontal: theme('spacing.7');
		--gap-vertical: theme('spacing.3');
		--icon-size: theme('spacing.12');
	}

	&--with-circle {
		#{$block}__icon {
			display: flex;
			position: relative;
			&::before {
				content: '';
				display: block;
				width: 95%;
				height: 95%;
				border-radius: 50%;
				background-color: theme('colors.gray.100');
				position: absolute;
				top: 0;
				left: 0;
			}
			svg {
				transform: translate(10%, -10%);
			}
		}
	}
}
