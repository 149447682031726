@import 'litto-lib/styles';
@import 'components';

body {
	height: 100%;
}

td {
	padding: theme('spacing.4') 0;
	// margin-bottom: 20px;
}
.side-sheet .ReactModal__Content {
	transform: translateX(100%);
}
.side-sheet .ReactModal__Content--after-open {
	transform: translateX(0%);
}
