.listingTable {
	border-radius: 10px;
	tbody {
		border-radius: 10px;
	}

	tbody tr {
		display: flex;
		flex-direction: column;
		@media (min-width: theme('screens.md')) {
			display: table-row;
		}
		-webkit-box-shadow: 0px 1px 0px 0px theme('colors.gray.100');
		-moz-box-shadow: 0px 1px 0px 0px theme('colors.gray.100');
		box-shadow: 0px 1px 0px 0px theme('colors.gray.100');
		// padding: 0 24px !important;
		border-radius: 10px;
		&:hover {
			background-color: theme('colors.gray.50');
		}
		td:first-child {
			border-radius: 10px 0 0 10px;
		}
		td:last-child {
			border-radius: 0px 10px 10px 0px;
		}
	}
}
