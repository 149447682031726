.client-portal-react-select {
	.react-select__value-container {
		padding: 12px 20px !important;
		font-size: 16px;
		cursor: pointer;
	}

	.react-select__control {
		border-radius: 10px !important;
	}

	.react-select__control:focus {
		border-radius: 10px !important;
	}

	.react-select__control:hover {
		border-radius: 10px !important;
	}

	.react-select__option {
		
		cursor: pointer;
		&--is-selected {
			color: white !important;
            background: theme('colors.primary.500') !important;
		}
	}
}
